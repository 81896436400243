import React, {Component} from 'react';
import { DatePicker, message } from 'antd';
import 'react-owl-carousel2/src/owl.carousel.css';
import Header from "../shared/components/Header";
import Footer from "../shared/components/Footer";
import moment from "moment";
import {empty} from "../shared/helpers/generalHelper";
import {Link} from "react-router-dom";

class SignUp extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            step: 1,
            name: '',
            name_cn: '',
            gender: '',
            place_of_birth: '',
            date_of_birth: '',
            teochew_father: '',
            teochew_mother: '',
            occupation: '',
            ptci_referrer: '',
            address: '',
            address_city: '',
            address_province: '',
            address_country: '',
            phone: '',
            is_phone_wa: false,
            email: '',
            password: '',
            password_conf: ''
        };
        this.state = {...this.initialState};
    }

    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const {
            name,
            name_cn,
            gender,
            place_of_birth,
            date_of_birth,
            teochew_father,
            teochew_mother,
            occupation,
            ptci_referrer,
            address,
            address_city,
            address_province,
            address_country,
            phone,
            is_phone_wa,
            email,
            password,
            password_conf
        } = this.state;

        if(empty(name)){
            message.error('Nama harus diisi');
        }else if(empty(name_cn)){
            message.error('Nama Mandarin harus diisi');
        }else if(empty(gender)){
            message.error('Gender harus dipilih');
        }else if(empty(place_of_birth)){
            message.error('Tempat lahir harus diisi');
        }else if(empty(date_of_birth)){
            message.error('Tanggal lahir harus dipilih');
        }else if(teochew_father!==1 && teochew_father!==0){
            message.error('Status Teo Chew Ayah harus dipilih');
        }else if(teochew_mother!==1 && teochew_mother!==0){
            message.error('Status Teo Chew Ibu harus dipilih');
        }else if(empty(occupation)){
            message.error('Pekerjaan harus diisi');
        }else if(empty(ptci_referrer)){
            message.error('Bagaimana Anda mengenal PTCI harus diisi');
        }else if(empty(phone)){
            message.error('No. Handphone harus diisi');
        }else if(empty(email)){
            message.error('Email harus diisi');
        }else if(empty(password)){
            message.error('Password harus diisi');
        }else if(empty(password_conf)){
            message.error('Konfirmasi password harus diisi');
        }else if(password !== password_conf){
            message.error('Password dan konfirmasi tidak cocok');
        }else{
            message.success('Anda telah berhasil mendaftar!');
        }

    };

    render() {
        const {
            step,
            name,
            name_cn,
            gender,
            place_of_birth,
            date_of_birth,
            teochew_father,
            teochew_mother,
            occupation,
            ptci_referrer,
            address,
            address_city,
            address_province,
            address_country,
            phone,
            is_phone_wa,
            email,
            password,
            password_conf
        } = this.state;
        const dateFormat = 'YYYY-MM-DD';
        return (
            <React.Fragment>
                <Header/>

                <section
                    className="content-top-margin page-title page-title-small border-bottom-light border-top-light bg-gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-12 wow fadeInUp" data-wow-duration="300ms">
                                <h1 className="black-text">Pendaftaran Member</h1>
                            </div>
                            <div className="col-md-4 col-sm-12 breadcrumb text-uppercase wow fadeInUp xs-display-none"
                                 data-wow-duration="600ms">
                                <p className="text-right">
                                    Sudah Punya Akun? <Link to="/sign-in">Sign In</Link>
                                </p>
                                {/*<ul className="text-right">*/}
                                    {/*/!*<li><a href="#">Home</a></li>*!/*/}
                                    {/*/!*<li><a href="#">Pages</a></li>*!/*/}
                                    {/*<li>Sudah Punya Akun? <Link to="/sign-in">LOGIN</Link></li>*/}
                                {/*</ul>*/}
                            </div>
                        </div>
                    </div>
                </section>

                {step === 1 && (
                    <section style={{paddingTop: 50}}>
                        <div className="container">
                            <div className="row no-margin-top">
                                <div className="col-md-12 col-sm-12">
                                    <p className="text-large font-weight-600 text-black margin-three no-margin-top  margin-two-bottom">
                                        Syarat menjadi anggota
                                    </p>
                                    <ol className="tet-med" style={{paddingLeft: 15}}>
                                        <li>Berusia sekurang-kurangnya 18 (delapan) belas tahun dan maksimal 55 (lima
                                            puluh lima) tahun.
                                        </li>
                                        <li>Ayah calon anggota adalah orang Teochew.</li>
                                        <li>Permohonan menjadi anggota harus ditujukan langsung kepada Dewan Pengurus.
                                        </li>
                                        <li>Penerimaan menjadi anggota ditetapkan oleh Dewan Pengurus dengan Surat
                                            Keputusan Dewan Pengurus. Keanggotaan dimulai terhitung sejak ditetapkan
                                            dalam Surat Keputusan Dewan Pengurus.
                                        </li>
                                    </ol>

                                    <br/>
                                    <p className="text-large font-weight-600 text-black margin-three no-margin-top  margin-two-bottom">
                                        Berakhirnya Masa Keanggotaan
                                    </p>
                                    <p className="text-med no-margin-bottom">
                                        Keanggotaan berakhir jika:
                                    </p>
                                    <ol className="tet-med" style={{paddingLeft: 15}}>
                                        <li>Mengundurkan diri atas permintaan sendiri.</li>
                                        <li>Pencabutan dan pembekuan sementara karena melanggar ketentuan, kewajiban
                                            organisasi yang ditetapkan dengan Keputusan Rapat yang khusus diadakan untuk
                                            itu.
                                        </li>
                                    </ol>

                                    <br/>
                                    <p className="text-large font-weight-600 text-black margin-three no-margin-top  margin-two-bottom">
                                        Hak dan Kewajiban Anggota
                                    </p>
                                    <p className="text-med no-margin-bottom">
                                        Hak Anggota:
                                    </p>
                                    <ol className="tet-med" style={{paddingLeft: 15}}>
                                        <li>Menghadiri rapat yang diadakan.</li>
                                        <li>Menghadiri Rapat Umum Anggota.</li>
                                        <li>Menyatakan pendapat dan saran.</li>
                                        <li>Memilih dan dipilih menjadi pengurus.</li>
                                        <li>Membela diri.</li>
                                    </ol>
                                    <p className="text-med no-margin-bottom">
                                        Kewajiban Anggota:
                                    </p>
                                    <ol className="tet-med" style={{paddingLeft: 15}}>
                                        <li>Mematuhi AD ART dan ketentuan-ketentuan organisasi lainnya.</li>
                                        <li>Menjunjung tinggi nama baik dan kehormatan organisasi.</li>
                                        <li>Membayar uang sumbangan dan pungutan lainnya yang sah.</li>
                                        <li>Menyampaikan data dan informasi apabila diperlukan.</li>
                                    </ol>

                                    <br/>
                                    <p className="text-large font-weight-600 text-black margin-three no-margin-top margin-two-bottom">
                                        Tindakan Hukum Keanggotaan
                                    </p>
                                    <ol className="text-med" style={{paddingLeft: 15}}>
                                        <li>
                                            Tindakan hukum dapat dijatuhkan berupa:
                                            <ol className="text-med" style={{paddingLeft: 15}} type="a">
                                                <li>Peringatan tertulis.</li>
                                                <li>Pembekuan.</li>
                                                <li>Pencabutan keanggotaan.</li>
                                            </ol>
                                        </li>
                                        <li>
                                            Tindakan hukum dijatuhkan karena anggota:
                                            <ol className="text-med" style={{paddingLeft: 15}} type="a">
                                                <li>Melakukan kelalaian dan atau pelanggaran baik kepada organisasi
                                                    maupun hukum negara.
                                                </li>
                                                <li>Tidak membayar iuran dan kewajiban lainnya selama 6 (enam) bulan
                                                    berturut-turut.
                                                </li>
                                                <li>Tidak melaksanakan atau tidak menaati keputusan organisasi dan atau
                                                    melakukan tindakan yang melanggar hukum dalam arti luas.
                                                </li>
                                            </ol>
                                        </li>
                                        <li>Pembekuan dijatuhkan kepada anggota setelah 3 (tiga) kali diberi peringatan
                                            tertulis dan tidak mengindahkannya.
                                        </li>
                                        <li>Terhadap pembekuan, anggota yang bersangkutan mempunyai hak membela diri
                                            dalam rapat yang khusus diadakan atas permintaan yang bersangkutan
                                            selambat-lambatnya dalam waktu 3 (tiga) bulan setelah menerima pemberitahuan
                                            pembekuan atas dirinya.
                                        </li>
                                        <li>Apabila ternyata anggota tersebut tidak bersalah, pembekuan keanggotaan atas
                                            dirinya dicabut dan namanya direhabilitasi.
                                        </li>
                                        <li>Apabila ternyata anggota tersebut bersalah, pembekuan keanggotaan
                                            ditingkatkan menjadi pencabutan keanggotaan.
                                        </li>
                                        <li>Apabila tindakan hukuman yang dijatuhkan sebagaimana dimaksud ayat 6 pasal
                                            ini tidak dapat diterima, maka yang bersangkutan diberi kesempatan
                                            melaksanakan upaya hukum lainnya.
                                        </li>
                                        <li>Dalam hal anggota dijatuhi hukuman pidana dan telah mempunyai kekuatan hukum
                                            tetap, maka dilakukan pencabutan keanggotaan langsung terhadap yang
                                            bersangkutan.
                                        </li>
                                    </ol>

                                    <br/>
                                    <button className="btn btn-primary btn-lg" onClick={() => {
                                        this.setState({step: 2});
                                        window.scrollTo(0, 0);
                                    }}>Saya menyetujui syarat dan ketentuan di atas dan ingin mendaftar
                                    </button>
                                </div>
                            </div>

                            {/*<div className="wide-separator-line no-margin-lr"/>*/}
                        </div>
                    </section>
                )}

                {step === 2 && (
                    <section className="wow fadeIn" style={{paddingTop: 50}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5 col-sm-8 col-xs-11 center-col xs-no-padding">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group">
                                            <label className="text-uppercase">Nama Lengkap</label>
                                            <input type="text"
                                                   onChange={(e) => {
                                                       this.setState({name: e.target.value})
                                                   }}
                                                   value={name}
                                                   className="input-round big-input"/>
                                        </div>

                                        <div className="form-group">
                                            <label className="text-uppercase">Nama Mandarin</label>
                                            <input type="text"
                                                   onChange={(e) => {
                                                       this.setState({name_cn: e.target.value})
                                                   }}
                                                   value={name_cn}
                                                   className="input-round big-input"/>
                                        </div>

                                        <div className="form-group">
                                            <label className="text-uppercase">Gender</label>
                                            <br/>
                                            <label className="radio-inline">
                                                <input type="radio"
                                                       name="inlineRadioOptions"
                                                       id="inlineRadio1"
                                                       value="MALE"
                                                       checked={gender==='MALE'}
                                                       onChange={(e)=>{this.setState({gender: e.currentTarget.value})}}
                                                /> Laki-laki
                                            </label>
                                            <label className="radio-inline">
                                                <input type="radio"
                                                       name="inlineRadioOptions"
                                                       id="inlineRadio2"
                                                       value="FEMALE"
                                                       checked={gender==='FEMALE'}
                                                       onChange={(e)=>{this.setState({gender: e.currentTarget.value})}}
                                                /> Perempuan
                                            </label>
                                        </div>

                                        <div className="form-group">
                                            <label className="text-uppercase">Tempat Lahir</label>
                                            <input type="text"
                                                   onChange={(e) => {
                                                       this.setState({place_of_birth: e.target.value})
                                                   }}
                                                   value={place_of_birth}
                                                   className="input-round big-input"/>
                                        </div>

                                        <div className="form-group">
                                            <label className="text-uppercase">Tanggal Lahir</label>
                                            <br/>
                                            <DatePicker defaultValue={moment().subtract(17, 'years')}
                                                        format={dateFormat}
                                                        allowClear={true}
                                                        size="large"
                                                        style={{width: '100%'}}
                                                        onChange={(dateMoment, dateString) => {
                                                            this.setState({date_of_birth: dateString});
                                                        }}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label className="text-uppercase">Apakah Ayah Anda orang Teo Chew?</label>
                                            <br/>
                                            <label className="radio-inline">
                                                <input type="radio"
                                                       name="teochew_father"
                                                       value={1}
                                                       defaultChecked={teochew_father===1}
                                                       onChange={(e)=>{this.setState({teochew_father: e.currentTarget.value})}}
                                                /> Ya
                                            </label>
                                            <label className="radio-inline">
                                                <input type="radio"
                                                       name="teochew_father"
                                                       value={0}
                                                       defaultChecked={teochew_father===0}
                                                       onChange={(e)=>{this.setState({teochew_father: e.currentTarget.value})}}
                                                /> Tidak
                                            </label>
                                        </div>

                                        <div className="form-group">
                                            <label className="text-uppercase">Apakah Ibu Anda orang Teo Chew?</label>
                                            <br/>
                                            <label className="radio-inline">
                                                <input type="radio"
                                                       name="teochew_mother"
                                                       value={1}
                                                       defaultChecked={teochew_mother===1}
                                                       onChange={(e)=>{this.setState({teochew_mother: e.target.value})}}
                                                /> Ya
                                            </label>
                                            <label className="radio-inline">
                                                <input type="radio"
                                                       name="teochew_mother"
                                                       value={0}
                                                       defaultChecked={teochew_mother===0}
                                                       onChange={(e)=>{this.setState({teochew_mother: e.target.value})}}
                                                /> Tidak
                                            </label>
                                        </div>

                                        <div className="form-group">
                                            <label className="text-uppercase">Pekerjaan &amp; Jabatan</label>
                                            <input type="text"
                                                   onChange={(e) => {
                                                       this.setState({occupation: e.target.value})
                                                   }}
                                                   value={occupation}
                                                   className="input-round big-input"/>
                                        </div>

                                        <div className="form-group">
                                            <label className="text-uppercase">Darimanakah Anda mengetahui tentang PTCI?</label>
                                            <input type="text"
                                                   onChange={(e) => {
                                                       this.setState({ptci_referrer: e.target.value})
                                                   }}
                                                   value={ptci_referrer}
                                                   className="input-round big-input"/>
                                        </div>

                                        <div className="form-group">
                                            <label className="text-uppercase">No. Handphone</label>
                                            <input type="tel"
                                                   onChange={(e) => {
                                                       this.setState({phone: e.target.value})
                                                   }}
                                                   value={phone}
                                                   className="input-round big-input"/>
                                            {/*<br/>*/}
                                            <div className="checkbox">
                                                <label><input type="checkbox"
                                                              value="1"
                                                              onChange={()=>{
                                                                  this.setState({is_phone_wa: !is_phone_wa});
                                                              }}
                                                              defaultChecked={is_phone_wa}
                                                /> Whatsapp Aktif</label>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label className="text-uppercase">Email</label>
                                            <input type="email"
                                                   onChange={(e) => {
                                                       this.setState({email: e.target.value})
                                                   }}
                                                   value={email}
                                                   className="input-round big-input"/>
                                        </div>
                                        <div className="form-group">
                                            <label className="text-uppercase">Password</label>
                                            <input type="password"
                                                   onChange={(e) => {
                                                       this.setState({password: e.target.value})
                                                   }}
                                                   value={password}
                                                   className="input-round big-input"/>
                                        </div>
                                        <div className="form-group">
                                            <label className="text-uppercase">Re-Enter Password</label>
                                            <input type="password"
                                                   onChange={(e) => {
                                                       this.setState({password_conf: e.target.value})
                                                   }}
                                                   value={password_conf}
                                                   className="input-round big-input"/>
                                        </div>
                                        <button
                                            className="btn highlight-button-dark btn-round margin-five no-margin-right"
                                            type="submit">Register Now
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                )}

                <Footer/>
            </React.Fragment>
        );
    }
}

export default SignUp;
