import React from 'react';
import {Link} from "react-router-dom";

export default () => {
    return (
        <nav
            className="navbar navbar-default navbar-fixed-top nav-transparent overlay-nav sticky-nav nav-border-bottom bg-white"
            role="navigation">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 pull-left"><Link className="logo-light" to="/"><img
                        style={{width: 230, maxWidth: 230}} alt="" src="images/L2/logo-long.png"
                        className="logo"/></Link><Link className="logo-dark" to="/"><img
                        style={{width: 230, maxWidth: 230}} alt="" src="images/L2/logo-long.png"
                        className="logo"/></Link></div>
                    {/*<div className="col-md-2 no-padding-left search-cart-header pull-right">*/}
                    {/*<div id="top-search">
                                  <a href="#search-header" className="header-search-form"><i className="fa fa-search search-button"/></a>
                              </div>
                              <form id="search-header" method="post" action="#" name="search-header" className="mfp-hide search-form-result">
                                  <div className="search-form position-relative">
                                      <button type="submit" className="fa fa-search close-search search-button"/>
                                      <input type="text" name="search" className="search-input" placeholder="Enter your keywords..." autoComplete="off" />
                                  </div>
                              </form>
                              <div className="top-cart">
                                  <a href="#" className="shopping-cart">
                                      <i className="fa fa-shopping-cart"/>ub
                                      <div className="subtitle">(1) Items</div>
                                  </a>
                                  <div className="cart-content">
                                      <ul className="cart-list">
                                          <li>
                                              <a title="Remove item" className="remove" href="#">×</a>
                                              <a href="#">
                                                  <img width="90" height="90" alt="" src="http://placehold.it/90x90" />Leather Craft
                                              </a>
                                              <span className="quantity">1 × <span className="amount">$160</span></span>
                                              <a href="#">Edit</a>
                                          </li>
                                      </ul>
                                      <p className="total">Subtotal: <span className="amount">$160</span></p>
                                      <p className="buttons">
                                          <a href="shop-cart.html" className="btn btn-very-small-white no-margin-bottom margin-seven pull-left no-margin-lr">View Cart</a>
                                          <a href="shop-checkout.html" className="btn btn-very-small-white no-margin-bottom margin-seven no-margin-right pull-right">Checkout</a>
                                      </p>
                                  </div>
                              </div>*/}
                    {/*</div>*/}
                    <div className="navbar-header col-sm-8 col-xs-2 pull-right">
                        <button type="button" className="navbar-toggle" data-toggle="collapse"
                                data-target=".navbar-collapse"><span
                            className="sr-only">Toggle navigation</span> <span className="icon-bar"/> <span
                            className="icon-bar"/> <span className="icon-bar"/></button>
                    </div>
                    <div className="col-md-8 no-padding-right accordion-menu text-right">
                        <div className="navbar-collapse collapse">
                            <ul id="accordion" className="nav navbar-nav navbar-right panel-group">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="dropdown panel">
                                    <a href="#collapse1" className="dropdown-toggle collapsed"
                                       data-toggle="collapse" data-parent="#accordion"
                                       data-hover="dropdown">News <i className="fa fa-angle-down"/></a>
                                </li>
                                <li className="dropdown panel">
                                    <a href="#collapse1" className="dropdown-toggle collapsed"
                                       data-toggle="collapse" data-parent="#accordion"
                                       data-hover="dropdown">Events <i className="fa fa-angle-down"/></a>
                                </li>
                                <li className="dropdown panel">
                                    <a href="#collapse1" className="dropdown-toggle collapsed"
                                       data-toggle="collapse" data-parent="#accordion"
                                       data-hover="dropdown">Gallery <i className="fa fa-angle-down"/></a>
                                </li>
                                {/*<li className="dropdown panel">
                                    <a href="#collapse1" className="dropdown-toggle collapsed"
                                       data-toggle="collapse" data-parent="#accordion"
                                       data-hover="dropdown">Contact <i className="fa fa-angle-down"/></a>
                                </li>*/}
                                <li className="dropdown panel">
                                    <a href="#collapse1" className="dropdown-toggle collapsed"
                                       data-toggle="collapse" data-parent="#accordion"
                                       data-hover="dropdown">About <i className="fa fa-angle-down"/></a>
                                </li>
                                <li>
                                    <Link to="/sign-up">Join Us</Link>
                                </li>
                                <li>
                                    <Link to="/sign-in">Sign In</Link>
                                </li>
                                {/*<li className="dropdown panel">
                                          <a href="#collapse2" className="dropdown-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-hover="dropdown">One Page <i className="fa fa-angle-down"/></a>
                                          <ul id="collapse2" className="dropdown-menu mega-menu panel-collapse collapse mega-menu-full">
                                              <li className="mega-menu-column col-sm-3">
                                                  <ul>
                                                      <li className="dropdown-header">Pre Made Homepage</li>
                                                      <li><a href="onepage-bootstrap-slider.html">Home main</a></li>
                                                      <li><a href="onepage-fashion.html">Home fashion</a></li>
                                                      <li><a href="onepage-architecture.html">Home architecture</a></li>
                                                      <li><a href="onepage-spa.html">Home spa</a></li>
                                                      <li><a href="onepage-agency.html">Home agency</a></li>
                                                      <li><a href="onepage-agency-option2.html">Home agency - option 2 <span className="menu-new">new</span></a></li>
                                                      <li><a href="onepage-restaurant.html">Home restaurant</a></li>
                                                      <li><a href="onepage-travel-agency.html">Home travel agency</a></li>
                                                      <li><a href="onepage-corporate.html">Home corporate</a></li>
                                                      <li><a href="onepage-personal.html">Home personal</a></li>
                                                      <li><a href="onepage-wedding.html">Home wedding</a></li>
                                                      <li><a href="onepage-landing.html">Landing page</a></li>
                                                  </ul>
                                              </li>
                                              <li className="mega-menu-column col-sm-3">
                                                  <ul>
                                                      <li className="dropdown-header">Parallax Image / Typography</li>
                                                      <li><a href="onepage-parallax1.html">Parallax Image - option 1 </a></li>
                                                      <li><a href="onepage-parallax2.html">Parallax Image - option 2</a></li>
                                                      <li><a href="onepage-parallax3.html">Parallax Image - option 3 </a></li>
                                                      <li><a href="onepage-parallax4.html">Parallax Image - option 4</a></li>
                                                      <li><a href="onepage-parallax5.html">Parallax Image - option 5</a></li>
                                                  </ul>
                                              </li>
                                              <li className="mega-menu-column col-sm-3">
                                                  <ul>
                                                      <li className="dropdown-header">Other</li>
                                                      <li><a href="onepage-masonry-portfolio.html">Home - masonry portfolio</a></li>
                                                      <li><a href="onepage-background-slider.html">Home - background slider</a></li>
                                                      <li><a href="onepage-full-screen-video.html">Home - full screen video</a></li>
                                                      <li><a href="onepage-text-rotator.html">Home - text rotator</a></li>
                                                      <li><a href="onepage-full-width-image.html">Home - full width image</a></li>
                                                  </ul>
                                              </li>
                                              <li className="mega-menu-column col-sm-3">
                                                  <ul>
                                                      <li className="dropdown-header">Carousel slider</li>
                                                      <li><a href="onepage-bootstrap-slider.html">Bootstrap slider - full screen</a></li>
                                                      <li><a href="onepage-slider-revolution1.html">Revolution slider - full screen</a></li>
                                                      <li><a href="onepage-slider-revolution2.html">Revolution slider - half screen</a></li>
                                                      <li><a href="onepage-slider-owl1.html">owl slider - full screen</a></li>
                                                      <li><a href="onepage-slider-owl2.html">owl slider - half screen</a></li>
                                                  </ul>
                                              </li>
                                          </ul>
                                      </li>
                                      <li className="dropdown panel">
                                          <a href="#collapse5" className="dropdown-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-hover="dropdown">Portfolio <i className="fa fa-angle-down" /></a>
                                          <ul id="collapse5" className="dropdown-menu mega-menu panel-collapse collapse mega-menu-full">
                                              <li className="mega-menu-column col-sm-3">
                                                  <ul>
                                                      <li className="dropdown-header">Grid</li>
                                                      <li><a href="portfolio-grid-2columns.html">2 Columns</a></li>
                                                      <li><a href="portfolio-grid-3columns.html">3 Columns</a></li>
                                                      <li><a href="portfolio-grid-4columns.html">4 Columns</a></li>
                                                      <li><a href="portfolio-grid-5columns.html">5 Columns</a></li>
                                                  </ul>
                                                  <ul>
                                                      <li className="dropdown-header">Grid - Gutter</li>
                                                      <li><a href="portfolio-grid-gutter-2columns.html">2 Columns</a></li>
                                                      <li><a href="portfolio-grid-gutter-3columns.html">3 Columns</a></li>
                                                      <li><a href="portfolio-grid-gutter-4columns.html">4 Columns</a></li>
                                                      <li><a href="portfolio-grid-gutter-5columns.html">5 Columns</a></li>
                                                  </ul>
                                                  <ul>
                                                      <li className="dropdown-header">Grid with Title</li>
                                                      <li><a href="portfolio-grid-with-title-2columns.html">2 Columns</a></li>
                                                      <li><a href="portfolio-grid-with-title-3columns.html">3 Columns</a></li>
                                                      <li><a href="portfolio-grid-with-title-4columns.html">4 Columns</a></li>
                                                      <li><a href="portfolio-grid-with-title-5columns.html">5 Columns</a></li>
                                                  </ul>
                                              </li>
                                              <li className="mega-menu-column col-sm-3">
                                                  <ul>
                                                      <li className="dropdown-header">Wide</li>
                                                      <li><a href="portfolio-wide-2columns.html">2 Columns</a></li>
                                                      <li><a href="portfolio-wide-3columns.html">3 Columns</a></li>
                                                      <li><a href="portfolio-wide-4columns.html">4 Columns</a></li>
                                                      <li><a href="portfolio-wide-5columns.html">5 Columns</a></li>
                                                  </ul>
                                                  <ul>
                                                      <li className="dropdown-header">Wide - Gutter</li>
                                                      <li><a href="portfolio-wide-gutter-2columns.html">2 Columns</a></li>
                                                      <li><a href="portfolio-wide-gutter-3columns.html">3 Columns</a></li>
                                                      <li><a href="portfolio-wide-gutter-4columns.html">4 Columns</a></li>
                                                      <li><a href="portfolio-wide-gutter-5columns.html">5 Columns</a></li>
                                                  </ul>
                                                  <ul>
                                                      <li className="dropdown-header">Wide with Title</li>
                                                      <li><a href="portfolio-wide-with-title-2columns.html">2 Columns</a></li>
                                                      <li><a href="portfolio-wide-with-title-gutter-3columns.html">3 Columns</a></li>
                                                      <li><a href="portfolio-wide-with-title-gutter-4columns.html">4 Columns</a></li>
                                                      <li><a href="portfolio-wide-with-title-gutter-5columns.html">5 Columns</a></li>
                                                  </ul>
                                              </li>
                                              <li className="mega-menu-column col-sm-3">
                                                  <ul>
                                                      <li className="dropdown-header">Masonry</li>
                                                      <li><a href="portfolio-masonry-2columns.html">2 Columns</a></li>
                                                      <li><a href="portfolio-masonry-3columns.html">3 Columns</a></li>
                                                      <li><a href="portfolio-masonry-4columns.html">4 Columns</a></li>
                                                      <li><a href="portfolio-masonry-5columns.html">5 Columns</a></li>
                                                  </ul>
                                                  <ul>
                                                      <li className="dropdown-header">Other</li>
                                                      <li><a href="portfolio-parallax.html">Parallax</a></li>
                                                      <li><a href="portfolio-short-description.html">With Short Description</a></li>
                                                      <li><a href="portfolio-lightbox.html">Portfolio lightbox</a></li>
                                                  </ul>
                                              </li>
                                              <li className="mega-menu-column col-sm-3">
                                                  <ul>
                                                      <li className="dropdown-header">Single Project Page</li>
                                                      <li><a href="single-project-page1.html">Single Project Page 1</a></li>
                                                      <li><a href="single-project-page2.html">Single Project Page 2</a></li>
                                                      <li><a href="single-project-page3.html">Single Project Page 3</a></li>
                                                      <li><a href="single-project-page4.html">Single Project Page 4</a></li>
                                                      <li><a href="single-project-page5.html">Single Project Page 5</a></li>
                                                  </ul>
                                                  <ul>
                                                      <li className="dropdown-header">Ajax - Project Page</li>
                                                      <li><a href="onepage-single-project-page1.html" className="simple-ajax-popup-align-top">Single Project Page 1</a></li>
                                                      <li><a href="onepage-single-project-page2.html" className="simple-ajax-popup-align-top">Single Project Page 2</a></li>
                                                      <li><a href="onepage-single-project-page3.html" className="simple-ajax-popup-align-top">Single Project Page 3</a></li>
                                                      <li><a href="onepage-single-project-page4.html" className="simple-ajax-popup-align-top">Single Project Page 4</a></li>
                                                      <li><a href="onepage-single-project-page5.html" className="simple-ajax-popup-align-top">Single Project Page 5</a></li>
                                                  </ul>
                                              </li>
                                          </ul>
                                      </li>
                                      <li className="dropdown panel">
                                          <a href="#collapse4" className="dropdown-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-hover="dropdown">Pages <i className="fa fa-angle-down" /></a>
                                          <ul id="collapse4" className="dropdown-menu mega-menu panel-collapse collapse mega-menu-full">
                                              <li className="mega-menu-column col-sm-3">
                                                  <ul>
                                                      <li className="dropdown-header">Introduction</li>
                                                      <li><a href="about-us.html">About Us</a></li>
                                                      <li><a href="about-us-option2.html">About Us - Option 2 <span className="menu-new">new</span></a></li>
                                                      <li><a href="team-members.html">Team Members</a></li>
                                                      <li><a href="testimonials.html">Testimonials</a></li>
                                                      <li><a href="our-clients.html">Our Clients</a></li>
                                                      <li><a href="careers.html">Careers</a></li>
                                                      <li><a href="contact-us.html">Contact Us</a></li>
                                                      <li><a href="sitemap.html">Sitemap</a></li>
                                                  </ul>
                                              </li>
                                              <li className="mega-menu-column col-sm-3">
                                                  <ul>
                                                      <li className="dropdown-header">Utility</li>
                                                      <li><a href="services.html">Services</a></li>
                                                      <li><a href="faq.html">FAQ'S</a></li>
                                                      <li><a href="login.html">Login</a></li>
                                                      <li><a href="register.html">Register</a></li>
                                                      <li><a href="404.html">Not Found (404)</a></li>
                                                      <li><a href="maintenance.html">Maintenance Page</a></li>
                                                      <li><a href="home-countdown-timer.html">Under Construction</a></li>
                                                  </ul>
                                              </li>
                                              <li className="mega-menu-column col-sm-3">
                                                  <ul>
                                                      <li className="dropdown-header">Page Layout</li>
                                                      <li><a href="page-full-width.html">Full Width</a></li>
                                                      <li><a href="page-full-width-wide.html">Full Width - Wide</a></li>
                                                      <li><a href="page-left-sidebar.html">Left Sidebar</a></li>
                                                      <li><a href="page-right-sidebar.html">Right Sidebar</a></li>
                                                      <li><a href="page-both-sidebar.html">Both Sidebar</a></li>
                                                  </ul>
                                              </li>
                                              <li className="mega-menu-column col-sm-3">
                                                  <ul>
                                                      <li className="dropdown-header">Gallery</li>
                                                      <li><a href="gallery-1columns.html">1 Columns</a></li>
                                                      <li><a href="gallery-2columns.html">2 Columns</a></li>
                                                      <li><a href="gallery-3columns.html">3 Columns</a></li>
                                                      <li><a href="gallery-4columns.html">4 Columns</a></li>

                                                      <li><a href="gallery-6columns.html">6 Columns</a></li>
                                                  </ul>
                                              </li>
                                          </ul>
                                      </li>
                                      <li className="dropdown panel">
                                          <a href="#collapse3" className="dropdown-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-hover="dropdown">Elements<i className="fa fa-angle-down" /></a>
                                          <ul id="collapse3" className="dropdown-menu mega-menu panel-collapse collapse mega-menu-full">
                                              <li className="mega-menu-column col-sm-3">
                                                  <ul>
                                                      <li className="dropdown-header">Slider</li>
                                                      <li><a href="slider-bootstrap.html">Bootstrap</a></li>
                                                      <li><a href="slider-revolution.html">Revolution</a></li>
                                                      <li><a href="slider-owl.html">Owl</a></li>
                                                  </ul>
                                                  <ul>
                                                      <li className="dropdown-header">Header</li>
                                                      <li><a href="header-light.html">Light Version</a></li>
                                                      <li><a href="header-light-without-cart-search.html">Light Version - Option 2 <span className="menu-new">new</span></a></li>
                                                      <li><a href="header-dark.html">Dark Version</a></li>
                                                      <li><a href="header-transparent-dark.html">Transparent Version - Dark</a></li>
                                                      <li><a href="header-transparent-light.html">Transparent Version - Light</a></li>
                                                      <li><a href="header-static-sticky.html">Static Sticky</a></li>
                                                      <li><a href="onepage-hamburger.html">Hamburger Menu <span className="menu-new">new</span></a></li>
                                                      <li><a href="onepage-hamburger-option2.html">Hamburger Menu - Option 2 <span className="menu-new">new</span></a></li>
                                                      <li><a href="onepage-agency-option2.html">Hamburger Menu - Option 3 <span className="menu-new">new</span></a></li>
                                                  </ul>
                                              </li>
                                              <li className="mega-menu-column col-sm-3">
                                                  <ul>
                                                      <li className="dropdown-header">Page Titles</li>
                                                      <li><a href="page-title-white.html">Title white</a></li>
                                                      <li><a href="page-title-gray.html">Title gray</a></li>
                                                      <li><a href="page-title-dark-gray.html">Title dark gray</a></li>
                                                      <li><a href="page-title-black.html">Title black</a></li>
                                                      <li><a href="page-title-with-image.html">Title with image</a></li>
                                                      <li><a href="page-title-large.html">Title large</a></li>
                                                      <li><a href="page-title-small-white.html">Title small white</a></li>
                                                      <li><a href="page-title-small-gray.html">Title small gray</a></li>
                                                      <li><a href="page-title-small-dark-gary.html">Title small dark gray</a></li>
                                                      <li><a href="page-title-small-black.html">Title small black</a></li>
                                                      <li><a href="page-title-center-align.html">Title center align</a></li>
                                                  </ul>
                                              </li>

                                              <li className="mega-menu-column col-sm-3">
                                                  <ul>
                                                      <li className="dropdown-header">Other</li>
                                                      <li><a href="accordions.html"><i className="fa fa-plus-square" />Accordions</a></li>
                                                      <li><a href="progress-bar.html"><i className="fa fa-tasks" />Progress Bar</a></li>
                                                      <li><a href="buttons.html"><i className="fa fa-link" />Buttons</a></li>
                                                      <li><a href="features-box.html"><i className="fa fa-th-large" />Features Box</a></li>
                                                      <li><a href="form.html"><i className="fa fa-align-justify" />Form</a></li>
                                                      <li><a href="grid.html"><i className="fa fa-th" />Grid</a></li>
                                                      <li><a href="icons-et-line.html"><i className="fa fa-star-o" />Icons Et-line</a></li>
                                                      <li><a href="icons-font-awesome.html"><i className="fa fa-star" />Icons Font Awesome</a></li>
                                                      <li><a href="alert-massage.html"><i className="fa fa-comment" />Alert massage</a></li>
                                                      <li><a href="tabs.html"><i className="fa fa-outdent" />Tabs</a></li>
                                                      <li><a href="typography.html"><i className="fa fa-font" />Typography</a></li>
                                                      <li><a href="video-sound.html"><i className="fa fa-video-camera" />Video / Sound</a></li>
                                                      <li><a href="counter-and-skills.html"><i className="fa fa-circle-o-notch" />Counter and Skills</a></li>
                                                  </ul>
                                              </li>
                                              <li className="mega-menu-column col-sm-3">
                                                  <ul>
                                                      <li className="dropdown-header">Image Gallery</li>
                                                      <li><a href="single-image-lightbox.html">Single Image Lightbox</a></li>
                                                      <li><a href="lightbox-gallery.html">Lightbox Gallery</a></li>
                                                      <li><a href="zoom-gallery.html">Zoom gallery</a></li>
                                                      <li><a href="popup-with-form.html">Popup with Form</a></li>
                                                      <li><a href="modal-popup.html">Modal Popup</a></li>
                                                      <li><a href="open-youtube-video.html">Open YouTube Video</a></li>
                                                      <li><a href="open-vimeo-video.html">Open Vimeo Video</a></li>
                                                      <li><a href="open-google-map.html">Open Google Map</a></li>
                                                  </ul>
                                              </li>
                                          </ul>
                                      </li>
                                      <li className="dropdown panel">
                                          <a href="#collapse6" className="dropdown-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-hover="dropdown">Blog <i className="fa fa-angle-down" /></a>
                                          <ul id="collapse6" className="dropdown-menu panel-collapse collapse mega-menu-full" role="menu">
                                              <li className="mega-menu-column col-sm-3">
                                                  <ul>
                                                      <li className="dropdown-header">Grid</li>
                                                      <li><a href="blog-grid-2columns.html">2 Columns</a></li>
                                                      <li><a href="blog-grid-3columns.html">3 Columns</a></li>
                                                      <li><a href="blog-grid-4columns.html">4 Columns</a></li>
                                                      <li><a href="blog-grid-full-width.html">Full width</a></li>
                                                  </ul>
                                              </li>
                                              <li className="mega-menu-column col-sm-3">
                                                  <ul>
                                                      <li className="dropdown-header">Masonry</li>
                                                      <li><a href="blog-masonry-2columns.html">2 Columns</a></li>
                                                      <li><a href="blog-masonry-3columns.html">3 Columns</a></li>
                                                      <li><a href="blog-masonry-4columns.html">4 Columns</a></li>
                                                      <li><a href="blog-masonry-full-width.html">Full width</a></li>
                                                  </ul>
                                              </li>
                                              <li className="mega-menu-column col-sm-3">
                                                  <ul>
                                                      <li className="dropdown-header">Default</li>
                                                      <li><a href="blog-right-sidebar.html">Right sidebar</a></li>
                                                      <li><a href="blog-left-sidebar.html">Left sidebar</a></li>
                                                      <li><a href="blog-full-width.html">Full width</a></li>
                                                  </ul>
                                              </li>
                                              <li className="mega-menu-column col-sm-3">
                                                  <ul>
                                                      <li className="dropdown-header">Single</li>
                                                      <li><a href="blog-single-right-sidebar.html">Right sidebar</a></li>
                                                      <li><a href="blog-single-left-sidebar.html">Left sidebar</a></li>
                                                      <li><a href="blog-single-full-width.html">Full width</a></li>
                                                      <li><a href="blog-single-full-width-with-image-slider.html">Full width with image slider</a></li>
                                                      <li><a href="blog-single-full-width-with-lightbox-gallery.html">Full width with lightbox gallery</a></li>
                                                  </ul>
                                              </li>
                                          </ul>
                                      </li>
                                      <li className="dropdown panel simple-dropdown">
                                          <a href="#collapse7" className="dropdown-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-hover="dropdown">Shop <i className="fa fa-angle-down" /></a>
                                          <ul id="collapse7" className="dropdown-menu panel-collapse collapse" role="menu">
                                              <li className="dropdown-header">For Shopping Cart</li>
                                              <li><a href="home-shop.html">Shop homepage</a></li>
                                              <li><a href="shop-with-sidebar.html">Shop with sidebar - grid</a></li><li><a href="shop-with-sidebar-list.html">Shop with sidebar - list</a></li>
                                              <li><a href="shop-full-width.html">Shop full width</a></li>
                                              <li><a href="shop-single-product.html">Single product</a></li>
                                              <li><a href="shop-cart.html">Cart</a></li>
                                              <li><a href="shop-checkout.html">Checkout</a></li>
                                          </ul>
                                      </li>*/}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}