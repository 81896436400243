import React from 'react';
import ReactDOM from 'react-dom';
import moment from "moment";
import 'antd/dist/antd.min.css';
import './index.module.scss';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';

moment.locale('id');

ReactDOM.render(
    <Routes />,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
