import React, {Component} from 'react';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css';
import Header from "../shared/components/Header";
import Footer from "../shared/components/Footer";

class Home extends Component {

    /*componentDidMount() {
        const $ = window.$;
        setTimeout(() => {
            $('.owl-carousel').trigger('refresh.owl.carousel');
            $("#owl-demo").owlCarousel({
                navigation: true, // Show next and prev buttons
                slideSpeed: 300,
                paginationSpeed: 400,
                singleItem: true,
                transitionStyle : "fade",
                navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"]
            });
            console.log($("#owl-demo").owlCarousel({
                navigation: true, // Show next and prev buttons
                slideSpeed: 300,
                paginationSpeed: 400,
                singleItem: true,
                transitionStyle : "fade",
                navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"]
            }));
        }, 5000);
    }*/

    render() {
        return (
            <React.Fragment>
                <Header/>
                <section id="slider" className="no-padding">
                    <OwlCarousel ref="car" options={{
                        nav: true,
                        autoplay: true,
                        smartSpeed: 300,
                        dotsSpeed: true,
                        items: 1,
                        animateIn: 'fade',
                        animateOut: 'fade',
                        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"]
                    }} events={{
                        onResized: () => {}
                    }} >
                        <div className="item owl-bg-img" style={{backgroundImage: "url('./images/L2/a.jpeg')"}}>
                            <div className="opacity-light bg-black"/>
                            <div className="container full-screen position-relative">
                                <div className="slider-typography margin-five no-margin-bottom">
                                    <div className="slider-text-middle-main">
                                        <div className="slider-text-middle">
                                            {/*<span className="text-uppercase white-text letter-spacing-3">Lorem Ipsum is simply dummy text</span>*/}
                                            <h1 className="letter-spacing-2 white-text margin-three no-margin-bottom">Perhimpunan
                                                Teo Chew Indonesia<br/>Divisi Pemuda</h1>
                                            <a className="btn-small-white btn btn-medium margin-four no-margin-bottom no-margin-right"
                                               href="#">Join Us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                    {/*<div id="owl-demo"
                         className="owl-carousel owl-theme light-pagination dark-pagination-without-next-prev-arrow">
                        <div className="item owl-bg-img" style={{backgroundImage: "url('./images/L2/a.jpeg')"}}>
                            <div className="opacity-light bg-black"/>
                            <div className="container full-screen position-relative">
                                <div className="slider-typography margin-five no-margin-bottom">
                                    <div className="slider-text-middle-main">
                                        <div className="slider-text-middle">
                                            <span className="text-uppercase white-text letter-spacing-3">Lorem Ipsum is simply dummy text</span>
                                            <h1 className="letter-spacing-2 white-text margin-three no-margin-bottom">Perhimpunan
                                                Teo Chew Indonesia<br/>Divisi Pemuda</h1>
                                            <a className="btn-small-white btn btn-medium margin-four no-margin-bottom no-margin-right"
                                               href="#">Join Us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item owl-bg-img" style={{backgroundImage: "url('http://placehold.it/1920x1000')"}}>
                          <div className="opacity-light bg-black"/>
                          <div className="container full-screen position-relative" >
                              <div className="slider-typography margin-five no-margin-bottom">
                                  <div className="slider-text-middle-main">
                                      <div className="slider-text-middle">
                                          <span className="text-uppercase white-text letter-spacing-3">Lorem Ipsum is simply dummy text</span>
                                          <h1 className="letter-spacing-2 white-text margin-three no-margin-bottom">Interior Remodeling To<br/> Makes Your Life Easier.</h1>
                                          <a className="btn-small-white btn btn-medium margin-four no-margin-bottom no-margin-right" href="#">Our Portfolio</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="item owl-bg-img" style={{backgroundImage: "url('http://placehold.it/1920x1000')"}}>
                          <div className="opacity-light bg-black"/>
                          <div className="container full-screen position-relative" >
                              <div className="slider-typography margin-five no-margin-bottom">
                                  <div className="slider-text-middle-main">
                                      <div className="slider-text-middle">
                                          <span className="text-uppercase white-text letter-spacing-3">Lorem Ipsum is simply dummy text</span>
                                          <h1 className="letter-spacing-2 white-text margin-three no-margin-bottom">Our Highly Expertise<br/> For Interior Design.</h1>
                                          <a className="btn-small-white btn btn-medium margin-four no-margin-bottom no-margin-right" href="#">Our Portfolio</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>*/}
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div
                                className="col-md-5 col-sm-8 dividers-header double-line center-col text-center margin-ten no-margin-top">
                                <div className="subheader bg-white">
                                    <h3 className="title-med no-padding-bottom letter-spacing-2">Perkenalan Singkat</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row no-margin-top">
                            <div className="col-md-6 col-sm-12">
                                <p className="text-large font-weight-600 text-black margin-three no-margin-top">
                                    SEJARAH
                                </p>
                                {/*<p className="text-large text-black margin-three no-margin-top">Lorem Ipsum is simply
                                  dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                  industry's standard dummy text.</p>*/}
                                <p className="text-med">
                                    Sejak tahun 1980 Konferensi Internasional Teochew digelar. Konferensi pertama
                                    digelar pada 1981 yang diprakarsai oleh Federasi Teochew Malaysia. Konferensi ini
                                    kemudian menghasilkan kesepakatan untuk membentuk Federasi Internasional Teochew
                                    yang diadakan setiap dua tahun sekali. Guna memperkuat jaringan dan komunikasi di
                                    antara pemuda Teochew di Indonesia, maka dengan semangat para pemuda Teochew yang
                                    telah bergabung dalam kegiatan-kegiatan di Perhimpunan Teo Chew Indonesia selama ini
                                    timbulah ide untuk membentuk divisi pemuda. Perhimpunan Teochew Indonesia Divisi
                                    Pemuda didirikan karena kami menyadari bahwa suku Teochew adalah salah satu suku
                                    China dengan populasi terbanyak di seluruh dunia. Pembentukan divisi pemuda ini
                                    diharapkan dapat diteruskan ke generasi selanjutnya untuk bersatu mengembangkan
                                    semangat dan budaya Teochew.
                                </p>
                                {/*<p className="text-med sm-margin-bottom-four">Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                                  text ever since the 1500s.</p>*/}
                                <p className="text-large font-weight-600 text-black margin-three no-margin-top">
                                    VISI
                                </p>
                                <p className="text-med">
                                    Bersatu mengembangkan semangat dan budaya Teochew.
                                </p>

                                <p className="text-large font-weight-600 text-black margin-three no-margin-top">
                                    MISI
                                </p>
                                <ol className="text-med" style={{paddingLeft: 15}}>
                                    <li>Menyiapkan jaringan dan kerjasama bagi komunitas Teochew.</li>
                                    <li>Memberdayakan anggota untuk melakukan kegiatan sosial bagi komunitas Teochew dan
                                        masyarakat.
                                    </li>
                                    <li>Mendukung pengembangan Pendidikan dan Kebudayaan bagi masyarakat.</li>
                                </ol>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <img src="https://placehold.it/1920x1200" alt=""/>
                            </div>
                        </div>

                        <div className="wide-separator-line no-margin-lr"/>

                        <div className="row no-margin-top">
                            <div className="col-md-12 col-sm-12">
                                <p className="text-large font-weight-600 text-black margin-three no-margin-top">
                                    KEGIATAN
                                </p>
                                <p className="text-med">
                                    Divisi pemuda saat ini memiliki beberapa kegiatan rutin, seperti sharing & gathering
                                    dalam hal bisnis dan professional, makan bersama, kelas mandarin, dan
                                    menyelenggarakan seminar dan diskusi dalam hal bisnis, serta berbagai kegiatan dalam
                                    bidang sosial dan budaya.
                                </p>
                            </div>
                        </div>

                        <div className="wide-separator-line no-margin-lr"/>

                        <div className="row no-margin-top">
                            <div className="col-md-12 col-sm-12">
                                <p className="text-large font-weight-600 text-black margin-three no-margin-top">
                                    BIDANG-BIDANG DIVISI PEMUDA
                                </p>
                                <p className="text-med">
                                    Divisi pemuda terdiri atas lima bidang, dimana setiap bidang memiliki tugas dan
                                    tanggung jawab sebagai berikut:
                                </p>
                                <ol className="text-med" style={{paddingLeft: 15}}>
                                    <li>
                                        Bidang Hubungan Antar Lembaga dan Kerjasama<br/>
                                        Tugas utama: Menjalin hubungan yang baik dengan perhimpunan Teochew divisi
                                        pemuda di berbagai tempat di Indonesia.
                                    </li>
                                    <li>
                                        Bidang Organisasi dan Anggota<br/>
                                        Tugas utama: Menerima pendaftaran anggota baru dan mengarsip data anggota,
                                        menjelaskan hak dan kewajiban kepada anggota baru, serta menerima kritik dan
                                        saran dari anggota selama bergabung.
                                    </li>
                                    <li>
                                        Bidang Progam dan Rencana<br/>
                                        Tugas utama: Merencanakan kegiatan selama setahun dan mendukung serta membantu
                                        jalannya rencana program dari bidang lain.
                                    </li>
                                    <li>
                                        Bidang Sosial dan Budaya<br/>
                                        Tugas utama: Mendukung dan mendorong anggota untuk berpartisipasi dalam kegiatan
                                        sosial dan budaya yang telah direncanakan dan diselenggarakan, seperti kunjungan
                                        ke museum, kunjungan ke panti jompo dan panti asuhan.
                                    </li>
                                    <li>
                                        Bidang Bisnis dan Profesional<br/>
                                        Tugas utama: Merencanakan dan mengatur kegiatan yang berhubungan dengan bisnis
                                        seperti seminar dan forum diskusi, menghubungi pembicara dan mendorong anggota
                                        untuk berpartisipasi.
                                    </li>
                                </ol>
                            </div>
                        </div>


                        {/*<div className="row margin-five no-margin-top">
                          <div className="col-md-4 col-sm-4 xs-margin-bottom-ten">
                              <img src="http://placehold.it/800x502" alt="" />
                                  <p className="text-uppercase letter-spacing-2 black-text font-weight-600 margin-ten no-margin-bottom">Design houses</p>
                                  <p className="margin-two text-med width-90">Lorem Ipsum is simply dummy text of the printing &amp; typesetting industry.</p>
                          </div>
                          <div className="col-md-4 col-sm-4 xs-margin-bottom-ten">
                              <img src="http://placehold.it/800x502" alt="" />
                                  <p className="text-uppercase letter-spacing-2 black-text font-weight-600 margin-ten no-margin-bottom">Interior design</p>
                                  <p className="margin-two text-med width-90">Lorem Ipsum is simply dummy text of the printing &amp; typesetting industry.</p>
                          </div>
                          <div className="col-md-4 col-sm-4">
                              <img src="http://placehold.it/800x502" alt="" />
                                  <p className="text-uppercase letter-spacing-2 black-text font-weight-600 margin-ten no-margin-bottom">Industrial premises</p>
                                  <p className="margin-two text-med width-90">Lorem Ipsum is simply dummy text of the printing &amp; typesetting industry.</p>
                          </div>
                      </div>
                      <div className="row border-top padding-five no-padding-bottom" id="counter">
                          <div className="col-md-3 col-sm-6 bottom-margin text-center counter-section wow fadeInUp sm-margin-bottom-ten" data-wow-duration="300ms"><span className="timer counter-number main-font font-weight-700" data-to="312" data-speed="7000"/><span className="counter-title">Finished projects</span> </div>
                          <div className="col-md-3 col-sm-6 bottom-margin text-center counter-section wow fadeInUp sm-margin-bottom-ten" data-wow-duration="600ms"><span className="timer counter-number main-font font-weight-700" data-to="980" data-speed="7000"/> <span className="counter-title">Happy customers</span> </div>
                          <div className="col-md-3 col-sm-6 bottom-margin-small text-center counter-section wow fadeInUp xs-margin-bottom-ten" data-wow-duration="900ms"><span className="timer counter-number main-font font-weight-700" data-to="741" data-speed="7000"/> <span className="counter-title">Working hours</span> </div>
                          <div className="col-md-3 col-sm-6 text-center counter-section wow fadeInUp" data-wow-duration="1200ms"><span className="timer counter-number main-font font-weight-700" data-to="480" data-speed="7000"/> <span className="counter-title">Comments Reserved</span> </div>
                      </div>*/}
                    </div>
                </section>
                {/*<section className="fix-background" style={{backgroundImage: "url('http://placehold.it/1920x1100')"}}>
                  <div className="opacity-full bg-white"/>
                  <div className="container position-relative">
                      <div className="row">
                          <div className="col-md-4 col-sm-12 sm-margin-bottom-ten sm-text-center">
                              <h3 className="title-med no-padding-bottom letter-spacing-2">Why Choose Us?</h3>
                              <p className="text-med margin-five">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                              <a className="highlight-button-dark btn btn-small button" href="#">Read More</a>
                          </div>
                          <div className="col-md-2 col-sm-4 col-md-offset-2 text-center margin-three xs-margin-bottom-ten">
                              <i className="icon-profile-male medium-icon black-text display-block" />
                              <h1 className="font-weight-600 margin-five no-margin-bottom">300+</h1>
                              <p className="text-uppercase black-text letter-spacing-2 text-small margin-three">People Team</p>
                          </div>
                          <div className="col-md-2 col-sm-4 text-center margin-three xs-margin-bottom-ten">
                              <i className="icon-global medium-icon black-text display-block" />
                              <h1 className="font-weight-600 margin-five no-margin-bottom">385+</h1>
                              <p className="text-uppercase black-text letter-spacing-2 text-small margin-three">Houses Built</p>
                          </div>
                          <div className="col-md-2 col-sm-4 text-center margin-three">
                              <i className="icon-bargraph medium-icon black-text display-block" />
                              <h1 className="font-weight-600 margin-five no-margin-bottom">287+</h1>
                              <p className="text-uppercase black-text letter-spacing-2 text-small margin-three">Offices Built</p>
                          </div>
                      </div>
                  </div>
              </section>
              <section>
                  <div className="container">
                      <div className="row">
                          <div className="col-md-5 col-sm-8 dividers-header double-line center-col text-center margin-ten no-margin-top">
                              <div className="subheader bg-white">
                                  <h3 className="title-med no-padding-bottom letter-spacing-2">Featured Projects</h3>
                              </div>
                          </div>
                      </div>
                      <div className="row">
                          <div className="col-md-3 col-sm-6 text-center sm-margin-bottom-ten"><img alt="" src="http://placehold.it/265x290" /><h5 className="margin-ten no-margin-bottom xs-margin-top-five">Fringila Auctor</h5></div>
                          <div className="col-md-3 col-sm-6 text-center sm-margin-bottom-ten"><img alt="" src="http://placehold.it/265x290" /><h5 className="margin-ten no-margin-bottom xs-margin-top-five">Vitae nidh mauris</h5></div>
                          <div className="col-md-3 col-sm-6 text-center xs-margin-bottom-ten"><img alt="" src="http://placehold.it/265x290" /><h5 className="margin-ten no-margin-bottom xs-margin-top-five">In fringilla auctor</h5></div>
                          <div className="col-md-3 col-sm-6 text-center"><img alt="" src="http://placehold.it/265x290" /><h5 className="margin-ten no-margin-bottom xs-margin-top-five">Aliquam gravida molis</h5></div>
                      </div>
                  </div>
              </section>
              <section id="projects" className="grid-wrap work-4col margin-top-section no-margin-top border-top no-padding-bottom">
                  <div className="container">
                      <div className="row">
                          <div className="col-md-5 col-sm-8 dividers-header double-line center-col text-center">
                              <div className="subheader bg-white">
                                  <h3 className="title-med no-padding-bottom letter-spacing-2">Projects</h3>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="container-fluid">
                      <div className="row no-padding">
                          <div className="col-lg-3 col-md-8 col-sm-11 margin-three center-col text-center">
                              <h4 className="gray-text">Lorem Ipsum is simply dummy text of the printing & typesetting industry. Lorem Ipsum has been the industry's standard dummy </h4>
                          </div>
                          <div className="col-md-12 text-center" >
                              <div className="text-center">
                                  <ul className="portfolio-filter nav nav-tabs">
                                      <li className="nav active"><a href="#" data-filter="*">All</a></li>
                                      <li className="nav"><a href="#" data-filter=".commercial">Commercial</a></li>
                                      <li className="nav"><a href="#" data-filter=".residential">Residential</a></li>
                                      <li className="nav"><a href="#" data-filter=".kitchen">Kitchen</a></li>
                                      <li className="nav"><a href="#" data-filter=".remodeling">Remodeling</a></li>
                                  </ul>
                              </div>
                          </div>
                          <div className="grid-gallery overflow-hidden" >
                              <div className="tab-content">
                                  <ul className="masonry-items grid lightbox-gallery">
                                      <li className="kitchen commercial">
                                          <figure>
                                              <div className="gallery-img"><a href="onepage-single-project-page1.html" className="simple-ajax-popup-align-top" title=""><img src="http://placehold.it/800x600" alt="" /></a></div>
                                              <figcaption>
                                                  <h3>Herbal Beauty Salon</h3>
                                                  <p>Ajax popup - Style #1</p>
                                              </figcaption>
                                          </figure>
                                      </li>
                                      <li className="remodeling">
                                          <figure>
                                              <div className="gallery-img"><a href="onepage-single-project-page2.html" className="simple-ajax-popup-align-top" title=""><img src="http://placehold.it/800x1200" alt="" /></a></div>
                                              <figcaption>
                                                  <h3>Tailoring Interior</h3>
                                                  <p>Ajax popup - Style #2</p>
                                              </figcaption>
                                          </figure>
                                      </li>
                                      <li className="commercial   residential kitchen">
                                          <figure>
                                              <div className="gallery-img"><a href="onepage-single-project-page3.html" className="simple-ajax-popup-align-top" title=""><img src="http://placehold.it/800x600" alt="" /></a></div>
                                              <figcaption>
                                                  <h3>Pixflow Studio</h3>
                                                  <p>Ajax popup - Style #3</p>
                                              </figcaption>
                                          </figure>
                                      </li>
                                      <li className="remodeling kitchen  commercial residential">
                                          <figure>
                                              <div className="gallery-img"><a href="onepage-single-project-page4.html" className="simple-ajax-popup-align-top" title=""><img src="http://placehold.it/800x600" alt="" /></a></div>
                                              <figcaption>
                                                  <h3>Kaya Skin Care</h3>
                                                  <p>Ajax popup - Style #4</p>
                                              </figcaption>
                                          </figure>
                                      </li>
                                      <li className=" kitchen  residential">
                                          <figure>
                                              <div className="gallery-img"><a href="onepage-single-project-page1.html" className="simple-ajax-popup-align-top" title=""><img src="http://placehold.it/800x600" alt="" /></a></div>
                                              <figcaption>
                                                  <h3>Third Eye Glasses</h3>
                                                  <p>Ajax popup - Style #1</p>
                                              </figcaption>
                                          </figure>
                                      </li>
                                      <li className="remodeling commercial ">
                                          <figure>
                                              <div className="gallery-img"><a href="onepage-single-project-page2.html" className="simple-ajax-popup-align-top" title=""><img src="http://placehold.it/800x600" alt="" /></a></div>
                                              <figcaption>
                                                  <h3>Rubber Studio</h3>
                                                  <p>Ajax popup - Style #2</p>
                                              </figcaption>
                                          </figure>
                                      </li>
                                      <li className="remodeling  residential">
                                          <figure>
                                              <div className="gallery-img"><a href="onepage-single-project-page3.html" className="simple-ajax-popup-align-top" title=""><img src="http://placehold.it/800x600" alt="" /></a></div>
                                              <figcaption>
                                                  <h3>Pixflow Studio</h3>
                                                  <p>Ajax popup - Style #3</p>
                                              </figcaption>
                                          </figure>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <section>
                  <div className="container">
                      <div className="row">
                          <div className="col-md-5 col-sm-8 dividers-header double-line center-col text-center margin-ten no-margin-top">
                              <div className="subheader bg-white">
                                  <h3 className="title-med no-padding-bottom letter-spacing-2">Team</h3>
                              </div>
                          </div>
                      </div>
                      <div className="row">
                          <div className="col-md-6 col-sm-12 sm-margin-bottom-ten">
                              <div className="col-lg-6 col-md-7 col-sm-6 no-padding">
                                  <div className="architecture-team team-member xs-no-padding">
                                      <span className="team-name text-uppercase black-text letter-spacing-2 display-block font-weight-600">Alexander Harvard</span>
                                      <span className="team-post text-uppercase letter-spacing-2 display-block">CEO / Architect</span>
                                      <div className="separator-line bg-black no-margin-lr margin-ten"/>
                                      <span className="margin-ten display-block clearfix xs-no-margin"/>
                                      <p className="margin-ten xs-no-margin-top">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                      <span className="margin-ten display-block clearfix xs-no-margin"/>
                                      <div className="person-social margin-ten xs-no-margin"><a href="#"><i className="fa fa-facebook black-text no-margin-left" /></a><a href="#"><i className="fa fa-twitter black-text" /></a><a href="#"><i className="fa fa-google-plus black-text" /></a></div>
                                  </div>
                              </div>
                              <div className="col-lg-6 col-md-5 col-sm-6 no-padding"><img src="http://placehold.it/500x550" alt="" className="xs-img-full"/></div>
                          </div>
                          <div className="col-md-6 col-sm-12 sm-margin-bottom-ten">
                              <div className="col-lg-6 col-md-7 col-sm-6 no-padding">
                                  <div className="architecture-team team-member xs-no-padding">
                                      <span className="team-name text-uppercase black-text letter-spacing-2 display-block font-weight-600">Jeremy Dupont</span>
                                      <span className="team-post text-uppercase letter-spacing-2 display-block">CEO / Architect</span>
                                      <div className="separator-line bg-black no-margin-lr margin-ten" />
                                      <span className="margin-ten display-block clearfix xs-no-margin" />
                                      <p className="margin-ten xs-no-margin-top">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                      <span className="margin-ten display-block clearfix xs-no-margin" />
                                      <div className="person-social margin-ten xs-no-margin"><a href="#"><i className="fa fa-facebook black-text no-margin-left" /></a><a href="#"><i className="fa fa-twitter black-text" /></a><a href="#"><i className="fa fa-google-plus black-text" /></a></div>
                                  </div>
                              </div>
                              <div className="col-lg-6 col-md-5 col-sm-6 no-padding"><img src="http://placehold.it/500x550" className="xs-img-full" alt=""/></div>
                          </div>
                          <div className="col-md-6 col-sm-12 sm-margin-bottom-ten">
                              <div className="col-lg-6 col-md-5 col-sm-6 no-padding xs-margin-bottom-ten"><img src="http://placehold.it/500x550" className="xs-img-full" alt=""/></div>
                              <div className="col-lg-6 col-md-7 col-sm-6 no-padding">
                                  <div className="architecture-team team-member xs-no-padding md-no-margin-bottom md-no-padding-bottom">
                                      <span className="team-name text-uppercase black-text letter-spacing-2 display-block font-weight-600">Herman Miller</span>
                                      <span className="team-post text-uppercase letter-spacing-2 display-block">Project Manager</span>
                                      <div className="separator-line bg-black no-margin-lr margin-ten" />
                                      <span className="margin-ten display-block clearfix xs-no-margin" />
                                      <p className="margin-ten xs-no-margin-top">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                      <span className="margin-ten display-block clearfix xs-no-margin" />
                                      <div className="person-social margin-ten xs-no-margin"><a href="#"><i className="fa fa-facebook black-text no-margin-left" /></a><a href="#"><i className="fa fa-twitter black-text" /></a><a href="#"><i className="fa fa-google-plus black-text" /></a></div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                              <div className="col-lg-6 col-md-5 col-sm-6 no-padding xs-margin-bottom-ten"><img src="http://placehold.it/500x550" className="xs-img-full" alt=""/></div>
                              <div className="col-lg-6 col-md-7 col-sm-6 no-padding">
                                  <div className="architecture-team team-member xs-no-padding md-no-margin-bottom md-no-padding-bottom">
                                      <span className="team-name text-uppercase black-text letter-spacing-2 display-block font-weight-600">Bryan Jonhson</span>
                                      <span className="team-post text-uppercase letter-spacing-2 display-block">Co Founder / CEO</span>
                                      <div className="separator-line bg-black no-margin-lr margin-ten"/>
                                      <span className="margin-ten display-block clearfix xs-no-margin" />
                                      <p className="margin-ten xs-no-margin-top">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                      <span className="margin-ten display-block clearfix xs-no-margin" />
                                      <div className="person-social margin-ten xs-no-margin"><a href="#"><i className="fa fa-facebook black-text no-margin-left" /></a><a href="#"><i className="fa fa-twitter black-text" /></a><a href="#"><i className="fa fa-google-plus black-text" /></a></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <section id="testimonial" className="border-top">
                  <div className="container">
                      <div className="row">
                          <div className="col-md-4 col-sm-4 testimonial-style2 text-center xs-margin-bottom-ten">
                              <img alt="" src="http://placehold.it/300x300" />
                                  <p className="center-col width-90">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                                  <div className="margin-two"><i className="fa fa-star yellow-text" /><i className="fa fa-star yellow-text" /><i className="fa fa-star yellow-text" /><i className="fa fa-star yellow-text" /><i className="fa fa-star yellow-text" /></div>
                                  <span className="name black-text">Alexander Harvard</span>
                          </div>
                          <div className="col-md-4 col-sm-4 testimonial-style2 text-center xs-margin-bottom-ten">
                              <img alt="" src="http://placehold.it/300x300" />
                                  <p className="center-col width-90">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                                  <div className="margin-two"><i className="fa fa-star yellow-text" /><i className="fa fa-star yellow-text" /><i className="fa fa-star yellow-text" /><i className="fa fa-star yellow-text" /><i className="fa fa-star yellow-text" /></div>
                                  <span className="name black-text">Herman Miller</span>
                          </div>
                          <div className="col-md-4 col-sm-4 testimonial-style2 text-center">
                              <img alt="" src="http://placehold.it/300x300" />
                                  <p className="center-col width-90">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                                  <div className="margin-two"><i className="fa fa-star yellow-text" /><i className="fa fa-star yellow-text" /><i className="fa fa-star yellow-text" /><i className="fa fa-star yellow-text" /><i className="fa fa-star yellow-text" /></div>
                                  <span className="name black-text">Bryan Johnson</span>
                          </div>
                      </div>
                  </div>
              </section>*/}
              <Footer/>
            </React.Fragment>
        );
    }
}

export default Home;
