import React, {Component} from 'react';
import OwlCarousel from 'react-owl-carousel2';
import {DatePicker, message} from 'antd';
import 'react-owl-carousel2/src/owl.carousel.css';
import Header from "../shared/components/Header";
import Footer from "../shared/components/Footer";
import moment from "moment";
import {Link} from "react-router-dom";
import {empty} from "../shared/helpers/generalHelper";

class SignIn extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            page: 'sign-in',
            email: '',
            password: ''
        };
        this.state = {...this.initialState};
    }

    handleSignIn = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const {
            email,
            password
        } = this.state;

        if(empty(email)){
            message.error('Email harus diisi');
        }else if(empty(password)){
            message.error('Password harus diisi');
        }else{
            message.success('Signed In');
        }
    };

    handleForgotPassword = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const {
            email
        } = this.state;

        if(empty(email)){
            message.error('Email harus diisi');
        }else{
            message.success('Password berhasil dikirimkan');
        }
    };

    render() {
        const {
            page,
            email,
            password
        } = this.state;
        return (
            <React.Fragment>
                <Header/>

                {page === 'sign-in' && (
                    <section className="wow fadeIn bg-gray" style={{paddingTop: 180}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4 col-sm-10 col-xs-11 center-col login-box">
                                    <h1>Sign In</h1>
                                    <p className="text-uppercase margin-three no-margin-bottom">Belum punya akun? <Link
                                        to="/sign-up">Daftar Sekarang</Link></p>
                                    <div className="separator-line bg-black no-margin-lr margin-ten"/>
                                    <form onSubmit={this.handleSignIn}>
                                        <div className="form-group">
                                            <label htmlFor="email" className="text-uppercase">Email</label>
                                            <input type="email"
                                                   name="email"
                                                   onChange={(e) => {
                                                       this.setState({email: e.target.value})
                                                   }}
                                                   value={email}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password" className="text-uppercase">Password</label>
                                            <input type="password"
                                                   name="password"
                                                   onChange={(e) => {
                                                       this.setState({password: e.target.value})
                                                   }}
                                                   value={password}
                                            />
                                        </div>
                                        <p>
                                            <button className="btn btn-link no-margin-top no-margin-bottom"
                                                    onClick={()=>{
                                                        this.setState({page: 'forgot-password'});
                                                    }}
                                            >Lupa password?</button>
                                        </p>
                                        <button className="btn btn-black no-margin-bottom btn-round no-margin-top"
                                                type="submit">Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                )}

                {page === 'forgot-password' && (
                    <section className="wow fadeIn bg-gray" style={{paddingTop: 180}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4 col-sm-10 col-xs-11 center-col login-box">
                                    <h1>Lupa Password</h1>
                                    <div className="separator-line bg-black no-margin-lr margin-ten"/>
                                    <form onSubmit={this.handleForgotPassword}>
                                        <div className="form-group">
                                            <label htmlFor="email" className="text-uppercase">Email</label>
                                            <input type="email"
                                                   name="email"
                                                   onChange={e => {
                                                       this.setState({email: e.target.value});
                                                   }}
                                                   value={email}
                                            />
                                        </div>
                                        <button className="btn btn-black btn-round no-margin-top"
                                                type="submit">Submit
                                        </button>
                                        <p>
                                            <button className="btn btn-link no-margin-top no-margin-bottom"
                                                    onClick={e => {
                                                        this.setState({page: 'sign-in'});
                                                    }}
                                            >Kembali ke Sign In</button>
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                )}

                <Footer/>
            </React.Fragment>
        );
    }
}

export default SignIn;
