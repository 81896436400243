import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import Home from './pages/Home';
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";

const browserHistory = createBrowserHistory();

export default () => {
    return (
        <Router history={browserHistory}>
            <Switch>
                <Route path="/sign-in" component={SignIn} />
                <Route path="/sign-up" component={SignUp} />
                <Route path="/" component={Home} />
            </Switch>
        </Router>
    );
};
